<template>
  <div class="about">
    <swiper :banner-list="bannerList" :is-contact="isContact"></swiper>
    <div class="content">
      <div class="contact">
        <div class="title">
          {{ contactTitle }}
        </div>
        <div class="formBox">
          <el-form
            label-width="80px"
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
          >
            <el-form-item>
              <h3>{{ contactInfo.tip1 }}</h3>
              <span class="contactInfoTip">{{ contactInfo.tip2 }}</span>
            </el-form-item>
            <el-form-item :label="contactInfo.yourname" prop="yourname">
              <el-input v-model="ruleForm.yourname"></el-input>
            </el-form-item>
            <el-form-item :label="contactInfo.email" prop="email">
              <el-input v-model="ruleForm.email"></el-input>
            </el-form-item>
            <el-form-item :label="contactInfo.content" prop="content">
              <el-input
                type="textarea"
                :rows="5"
                v-model="ruleForm.content"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')"
                >提交</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="address">
        <div class="title">
          {{ addressTitle }}
        </div>
        <div class="formBox">
          <div>
            <h2>{{ addressInfo.title1 }}</h2>
            <span class="contactInfoTip">{{ address }}</span>
          </div>

          <div id="map" class="map"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swiper from "../components/swiper.vue";

export default {
  name: "contact",
  components: {
    swiper
  },
  data() {
    return {
      isContact: true,
      contactTitle: "联系我们",
      addressTitle: "位置",
      bannerList: [],
      contactInfo: {
        tip1: "尊敬的朋友，",
        tip2:
          "与我们联系，一同交流学术，共同进步，发表您的见解我们将尽 快给您回复。",
        yourname: "您的名字",
        email: "您的邮箱",
        content: "您想说的"
      },
      ruleForm: {
        yourname: "",
        email: "",
        content: ""
      },
      rules: {
        yourname: [
          { required: true, message: "请输入您的名字", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 2 到 5 个字符", trigger: "blur" }
        ],
        email: [
          { required: true, message: "请输入您的邮箱", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"]
          }
        ],
        content: [
          { required: true, message: "请输入您想说的", trigger: "blur" }
        ]
      },
      addressInfo: {
        title1: "我们的地址",
        title2: "深圳市南山区西丽大学城北京大学深圳研究生院"
      }
    };
  },
  mounted() {
    this.initMap();
  },
  created() {
    this.$store.state.activeIndex = this.$route.path;
    this.getAbout();
  },
  computed: {
    address() {
      console.log(this.$store.state.vuex_config)
      if (this.$store.state.vuex_config.config) {
        return this.$store.state.vuex_config.config.all_addresses;
      }
      return this.addressInfo.title2;
    }
  },
  methods: {
    // 获取页面信息
    async getAbout() {
      // 获取swiper
      this.bannerList = await this.getblock("aboutswiper");
    },
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          // 这里用try catch包裹，请求失败的时候就执行catch里的
          try {
            let params = {
              // form_id: 4, // 自定义表单，联系我们
              yourname: this.ruleForm.yourname,
              email: this.ruleForm.email,
              content: this.ruleForm.content
            };
            let res = await this.$api.https.postFormId4(params);
            if (!res.code) {
              this.$message({
                message: res.msg
              });
              return;
            }
            this.$message({
              message: res.msg,
              type: "success",
              offset: 40
            });
            this.ruleForm = {
              yourname: "",
              email: "",
              content: ""
            };
          } catch (err) {
            this.loading = false;
            console.log(err);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    initMap() {
      let arr = this.$store.state.vuex_config.config.address.split(",") || [];
      
      let x = Number(arr[1]) || 39.992591;
      let y = Number(arr[0]) || 116.3107;
      // 定义地图中心点坐标
      let center = new window.TMap.LatLng(x, y);
      // 定义map变量，调用 TMap.Map() 构造函数创建地图
      let map = new window.TMap.Map(document.getElementById("map"), {
        center: center, // 设置地图中心点坐标
        zoom: 17.2, // 设置地图缩放级别
        pitch: 43.5, // 设置俯仰角
        rotation: 45 // 设置地图旋转角度
      });
      console.log(map);
      console.log(window);
    }
  }
};
</script>
<style lang="scss" scoped>
@mixin title($width) {
  .title {
    text-align: center;
    height: 40px;
    font-size: 36px;
    div:nth-child(2) {
      background: #cbe8ff;
      width: $width;
      height: 11px;
      border-radius: 5px;
      margin: -15px 0 0 0;
    }
  }
}
@mixin introduce() {
  .introduce {
    width: 100%;
    margin-top: 20px;
    line-height: 24px;
    color: #676767;
  }
}
$vue_color: #0495f3;
.about {
  background: #f7f8f9;
  text-align: left;
  color: #282828;
  font-size: 12px;
  .content {
    padding: 20px 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
      width: 400px;
      > .title {
        font-size: 22px;
      }
      > .formBox {
        margin-top: 16px;
        background: #ffffff;
        border-radius: 6px;
        min-height: 500px;
        box-sizing: border-box;
        padding: 40px 20px 20px 20px;
      }
    }
    > .contact {
      > .formBox {
        h3 {
          margin-bottom: 10px;
        }
        .contactInfoTip {
          font-size: 12px;
          line-height: 20px;
        }
        ::v-deep .el-form-item__content {
          line-height: 20px;
        }
        ::v-deep .el-form-item .el-form-item__label {
          font-size: 12px;
        }
      }
    }
    > .address {
      margin-left: 1%;
      > .formBox {
        display: flex;
        flex-direction: column;
        .map {
          height: 380px;
          margin-top: 20px;
        }
      }
    }
    .interests {
      display: flex;
      flex-direction: column;
      align-items: center;
      > .title {
        background-color: #0b6fbc;
        width: 618px;
        height: 73px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 28px;
        color: #ffffff;
      }
      .showImg {
        padding: 0 0 50px;
        width: 100%;
        min-height: 282px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        img {
          width: 233px;
          height: 282px;
        }
      }
    }
  }
}
</style>
